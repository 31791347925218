<template>
	<div
		v-if="title || documents.length"
		:id="$vnode.key"
		v
		class="c-block-list-document-list-custom"
	>
		<LongReadTarget
			v-if="title && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<LinkListLayout v-bind="{ title, links: computedDocuments }" />
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';
import LinkListLayout from '~/components/shared/LinkListLayout';

export default {
	name: 'BlockListDocumentListCustom',
	components: { LongReadTarget, LinkListLayout },
	inheritAttrs: false,

	props: {
		title: String,
		includeInToc: Boolean,
		documents: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		computedDocuments() {
			return this.documents?.map((document) => ({
				...document,
				url: document.path,
				type: 'media',
			}));
		},
	},
};
</script>
